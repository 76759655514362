<template>
  <v-app dark id="app" style="background: #333;">
    <router-view />
    <ModalConfirmAction />
    <ModalLoading />
    <ModalGlobalMessage />
  </v-app>
</template>

<script>
import ModalConfirmAction from "@/apps/shared/components/ModalConfirmAction";
import ModalLoading from "@/apps/shared/components/ModalLoading";
import ModalGlobalMessage from "@/apps/shared/components/ModalGlobalMessage";

export default {
  components: {
    ModalConfirmAction,
    ModalLoading,
    ModalGlobalMessage,
  }
};
</script>

<style>
.fonteMini {
  font-size: 8pt;
  color: #cccccc;
}
.click {
  cursor: pointer;
}
.item-table {
  border-radius: 6px;
  border: 1px solid #e2e2e2;
}
.bg-table {
  background: #f2f2f2;
}
.column {
  flex-direction: column;
}
.bg_white {
  background: #fff;
}
.expande-horizontal {
  display: flex;
  width: 100%;
}
.inflar {
  display: flex;
  width: 100%;
  min-height: 100vh;
}
.inflar-porcentagem {
  display: flex;
  width: 100%;
  height: 100%;
}
.centraliza {
  justify-content: center;
  align-items: center;
}
.wrap {
  flex-wrap: wrap;
}
.linha {
  flex-direction: row;
}
.fonteCash {
  /* font-family: 'Fredoka One', cursive; */
  font-family: "Righteous", cursive;
}
.fonteTitulo {
  font-family: "Righteous", cursive;
}
.fonte {
  font-family: "Righteous", cursive;
  /* font-family: 'Fredoka One', cursive; */
}

.menuAtivoHome {
  background: linear-gradient(to right, #cc9d1d, #fab54e);
  border-radius: 1px;
  box-shadow: 0 3px 5px 2px rgba(90, 62, 2, 0.2);
}
</style>
