import { http } from "@/apps/shared/baseService/config";

class Service {
  list() {
    return http.post("/user/product/list");
  }
  create(data) {
    return http.post("/admin/product/create-product", data);
  }
  update(data) {
    return http.post("/admin/product/update-product", data);
  }
  delete() {
    return http.post("/admin/product/delete-product");
  }
}

export default new Service();
