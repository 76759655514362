import { router } from "@/router";
import LoginService from "../service";

export const actions = {
  createSession({ commit, dispatch }, dados) {
    dispatch("enableLoading");

    LoginService.auth(dados)
      .then(res => {
        dispatch("disableLoading");
        commit("setLoggedUser", res.data.user);
        commit("setToken", res.data.token);
        sessionStorage.user = JSON.stringify(res.data.user);
        sessionStorage.token = JSON.stringify(res.data.token);
        setTimeout(() => location.reload(), 100);
        router.push("/dashboard");
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 3000
        });
      });
  },
  createUser({ commit, dispatch }, dados) {
    dispatch("enableLoading");
    LoginService.signup(dados)
      .then(() => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "success",
          message: "Cadastrado com sucesso!",
          timeout: 3000
        });
        router.push("/");
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 3000
        });
      });
  },
  async verifySponsor(state, payload) {
    const sponsor = await LoginService.verifySponsor(payload);
    state.commit("setSponsor", sponsor.data);
    return sponsor.data;
  },
  setModalForgotPassword({ commit }, payload) {
    commit("setModalForgotPassword", payload);
  },
  logout({commit}) {
      sessionStorage.clear()
      commit("setLoggedUser", false);
      commit("setToken", false);
      router.push('/')
  }
};

export default {};
