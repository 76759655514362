import SystemActionService from '../service'

export const actions = {
  listSystemActions({ commit, dispatch }) {
    dispatch("enableLoading")
    SystemActionService.list()
      .then(res => {
        dispatch("disableLoading")
        commit('setSystemActions', res.data)
      })
      .catch(e => {
        dispatch("disableLoading")
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.mesage,
          timeout: 6000
        })
      })
  },
  updateSystemAction({ dispatch }, systemaction) {
    dispatch("enableLoading")
    SystemActionService.update(systemaction)
      .then(res => {
        dispatch('listSystemActions', res.data)
        dispatch("disableLoading")
        dispatch("createGlobalMessage", {
          type: "success",
          message: "Parâmetro atualizado",
          timeout: 6000
        })
      })
      .catch(e => {
        dispatch("disableLoading")
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.mesage,
          timeout: 6000
        })
        
      })
  }
};

export default {};
