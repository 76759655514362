export const career_routes = [
  {
    path: "/careers",
    component: () => import("../Main.vue"),
    name: "Planos de Ativação"
  },
  {
    path: "/careers/view",
    component: () => import("../components/ViewCareer.vue"),
    name: "Visualizar Plano"
  },
  {
    path: "/careers/view/:id",
    props: true,
    component: () => import("../components/ViewCareer.vue"),
    name: "Visualizar Plano"
  },
  {
    path: "/premiacoes",
    component: () => import("../MainAward.vue"),
    name: "Premiações"
  },
];

export default {};
