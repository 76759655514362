import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { SystemActionsModule } from "@/apps/0#SystemActions/store";
import { UserLoggedModule } from "@/apps/2#userLogged/store";
import { SharedModule } from "@/apps/shared/store";
import { UsersModule } from "@/apps/2#users/store";
import { AuthModule } from "@/apps/2#auth/store";
import { UnilevelsModule } from "@/apps/3#unilevel/store";
import { ProductsModule } from "@/apps/4#product/store";
import { CareersModule } from "@/apps/5#careers/store";
import { PaymentMethodsModule } from "@/apps/7#paymentMethods/store";
import { TransactionsModule } from "@/apps/8#transactions/store";
import { AddressesModule } from "@/apps/9#addresses/store";
import { PurchasesModule } from "@/apps/11#purchases/store";
import { TicketsModule } from "@/apps/12#tickets/store";
import { DiscountsModule } from "@/apps/13#discountCoupons/store";
import { InvestmentsModule } from "@/apps/14#investments/store";
import Notification from "./apps/6#notifications/meta"

export const store = new Vuex.Store({
  modules: {
    UserLoggedModule,
    AuthModule,
    SharedModule,
    ProductsModule,
    InvestmentsModule,
    CareersModule,
    PurchasesModule,
    PaymentMethodsModule,
    TicketsModule,
    DiscountsModule,
    UnilevelsModule,
    TransactionsModule,
    AddressesModule,
    UsersModule,
    SystemActionsModule,
    mod_notification: Notification.module
  }
});

export default store;
