export const mutations = {
  setBalanceInvestment(state, payload) {
    state.balanceInvestment = payload
  },
  setBalanceToken(state, payload) {
    state.balanceToken = payload
  },
  setBalanceUnilevel(state, payload) {
    state.balanceUnilevel = payload
  },
};

export default {};
