import Vue from "vue";
import store from "@/store";
import App from "./App.vue";
import router from "@/router";
import vuetify from "@/plugins/vuetify";
import mask from "vue-the-mask";
import moment from "moment";
import "moment/locale/pt-br";
import Helper from "./apps/shared/helper";
import "@/apps/shared";
import { db, storage } from "./apps/shared/firebase";

Vue.use(mask);
Vue.prototype.$moment = moment;
Vue.config.productionTip = false;
Vue.prototype.$toLocaleString = value => {
  return value.toLocaleString("pt-br");
};

const theme = {
  primary: process.env.VUE_APP_COLOR,
  logo: process.env.VUE_APP_LOGO,
  token_img: process.env.VUE_APP_TOKEN_IMG
};

Vue.prototype.$theme = theme;
Vue.prototype.$db = db;
Vue.prototype.$storage = storage;
Vue.prototype.$helper = Helper;

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
