export const users_routes = [
  {
    path: "/users",
    component: () => import("../Main.vue"),
    name: "Listar Usuários"
  },
  {
    path: "/view-user/:id",
    props: true,
    component: () => import("../components/ViewProfile.vue"),
    name: "Visualizar Usuário"
  },
  {
    path: "/request-payments",
    props: true,
    component: () => import("../RequestPayments.vue"),
    name: "Saques"
  }
];

export default {};
