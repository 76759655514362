export const getters = {
  getUsers: state => state.users,
  getUser: state => state.user,
  getRequestPayments: state => state.requestPayments,
  getMarkedUsers: state => state.markedUsers,
  getModalAddBalance: state => state.modalAddBalance,
  getModalUpdatePass: state => state.modalUpdatePass,
  getAddBalance: state => state.addBalance
};

export default {};
