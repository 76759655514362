import { http } from "@/apps/shared/baseService/config";

class Service {
  list() {
    return http.post('/admin/systemaction/list')
  }
  update(data) {
    return http.post('/admin/systemaction/update', data)
  }
}

export default new Service();
