import { http } from "@/apps/shared/baseService/config";

class Service {
  list() {
    return http.post("/user/paymentmethod/list");
  }
  create(data) {
    return http.post("/admin/paymentmethod/create", data);
  }
  update(data) {
    return http.post("/admin/paymentmethod/update", data);
  }
  delete(data) {
    return http.post("/admin/paymentmethod/delete", data);
  }
}

export default new Service();
