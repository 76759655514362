import InvestmentService from "../service";
import { router } from "@/router";

export const actions = {
  listInvestments: ({ commit, dispatch }) => {
    dispatch("enableLoading");

    InvestmentService.list()
      .then(res => {
        dispatch("disableLoading");
        commit("setInvestments", res.data);
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 6000
        });
      });
  },
  createInvestment: ({ commit, dispatch, getters }) => {
    dispatch("enableLoading");

    InvestmentService.create({...getters.getInvestment, images: getters.getLinks})
      .then(() => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "success",
          message: "Registro concluído!",
          timeout: 6000
        });
        commit('setInvestment', {})
        commit('setModalViewInvestment', false)
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 6000
        });
      });
  },
  updateInvestment: ({ commit, dispatch, getters }) => {
    dispatch("enableLoading");

    InvestmentService.update({...getters.getInvestment, images: getters.getLinks})
      .then(() => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "success",
          message: "Registro concluído!",
          timeout: 6000
        });
        commit('setModalViewInvestment', false)
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 6000
        });
      });
  },
  abreModalViewInvestment({commit, dispatch}, investment) {
    commit('setInvestment', investment)
    if(investment.images) {
      commit('setLinks', investment.images)
    }
    commit('setModalViewInvestment', true)
  },
  deleteInvestment({ commit, dispatch, getters}) {
    dispatch("enableLoading");

    InvestmentService.deleteInvestment(getters.getInvestment)
      .then(() => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "success",
          message: "Exclusão concluída!",
          timeout: 6000
        });
        dispatch('listInvestments')
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 6000
        });
      });
  },
  fechaModalViewInvestment({commit, dispatch}) {
    commit('setInvestment', {})
    commit('setModalViewInvestment', false)
  },
  payInvestment: ({ commit, dispatch, getters }) => {
    dispatch("enableLoading");

    InvestmentService.payInvestment(getters.getPayInvestment)
      .then(() => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "success",
          message: "Registro concluído!",
          timeout: 6000
        });
        commit('setPayInvestment', {})
        commit('setModalViewInvestment', false)
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 6000
        });
      });
  },
  listPayInvestment: ({ commit, dispatch, getters }) => {
    dispatch("enableLoading");

    InvestmentService.listPayInvestment()
      .then(() => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "success",
          message: "Registro concluído!",
          timeout: 6000
        });
        commit('setInvestment', {})
        commit('setModalViewInvestment', false)
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 6000
        });
      });
  },
};

export default {};
