import { http } from "@/apps/shared/baseService/config";

class Service {
  list() {
    return http.post("/admin/purchase/list");
  }
  view(payload) {
    return http.post("admin/purchase/view", payload);
  }
  update(payload) {
    return http.post("admin/purchase/update", payload);
  }
  delete(payload) {
    return http.post("admin/purchase/delete", payload);
  }
  approve(payload) {
    return http.post("admin/purchase/approve-purchase", payload);
  }
  updateStatus(payload) {
    return http.post("admin/purchase/update-purchase-status", payload);
  }
  approve_frete(payload) {
    return http.post("admin/purchase/approve-frete", payload);
  }
}

export default new Service();
