import { http } from "@/apps/shared/baseService/config";

class Service {
  list() {
    return http.post("/user/investment/list");
  }
  create(data) {
    return http.post("/admin/investment/create-investment", data);
  }
  update(data) {
    return http.post("/admin/investment/update-investment", data);
  }
  listPayInvestments(data) {
    return http.post("/admin/investment/list-pay-investment", data);
  }
  payInvestment(data) {
    return http.post("/admin/investment/pay-investment", data);
  }
  deleteInvestment(data) {
    return http.post("/admin/investment/delete-investment", data);
  }
}

export default new Service();
