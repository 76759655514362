import { http } from "@/apps/shared/baseService/config";

class Service {
  list() {
    return http.post("/user/transaction/list");
  }
  list_reports() {
    return http.post("/admin/transaction/get-reports");
  }
}

export default new Service();
