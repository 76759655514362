import ProductService from "../service";
import { router } from "@/router";

export const actions = {
  listProducts: ({ commit, dispatch }, page) => {
    dispatch("enableLoading");

    ProductService.list()
      .then(res => {
        dispatch("disableLoading");
        commit("setProducts", res.data);
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 6000
        });
      });
  },
  saveProduct(state) {
    state.dispatch('enableLoading')
    if(state.getters.getProduct._id) {
      ProductService.update({ ...state.getters.getProduct, images: state.getters.getLinks })
        .then(() => {
          state.commit('setModalViewProduct', false)
          state.dispatch("disableLoading");
          state.dispatch("createGlobalMessage", {
            type: "success",
            message: 'Atualização concluída!',
            timeout: 6000
          });
        })
        .catch(e => {
          state.dispatch("disableLoading");
          state.dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        })
    } else {
      ProductService.create({...state.getters.getProduct, images: state.getters.getLinks })
    }
  },
  openModalViewProduct(state, payload) {
    state.commit('setProduct', payload)
    if(payload.images) {
      state.commit('setLinks', payload.images)
    }
    state.commit('setModalViewProduct', true)
  },
  closeModalViewProduct(state) {
    state.commit('setProduct', {})
    state.commit('setModalViewProduct', false)
  }
};

export default {};
