import CareerService from "../service";
import { router } from "@/router";

export const actions = {
  listCareers: ({ commit, dispatch }, page) => {
    dispatch("enableLoading");

    CareerService.list()
      .then(res => {
        dispatch("disableLoading");
        commit("setCareers", res.data);
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 6000
        });
      });
  },
  viewCareer({ commit, dispatch }, payload) {
    dispatch("enableLoading");
    if(payload._id) {
      CareerService.view(payload)
        .then(res => {
          dispatch("disableLoading");
          if(res.data.images) {
            commit("setLinks", res.data.images)
          } 
          commit("setCareer", res.data);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    }

  },
  createCareer({ dispatch, getters }) {
    dispatch("enableLoading");

    CareerService.create({ ...getters.getCareer, images: getters.getLinks  })
      .then(() => {
        dispatch("disableLoading");
        dispatch("listCareers");
        dispatch("createGlobalMessage", {
          type: "success",
          message: 'Plano criado com sucesso!',
          timeout: 6000
        });
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 6000
        });
      });
  },
  updateCareer({ dispatch, getters }) {
    dispatch("enableLoading");

    CareerService.update({ ...getters.getCareer, images: getters.getLinks  })
      .then(() => {
        dispatch("disableLoading");
        dispatch("listCareers");
        dispatch("createGlobalMessage", {
          type: "success",
          message: 'Plano atualizado com sucesso!',
          timeout: 6000
        });
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 6000
        });
      });
  }
};

export default {};
