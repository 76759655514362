export const purchase_routes = [
  {
    path: "/purchases",
    component: () => import("../Main.vue"),
    name: "Compras"
  },
  {
    path: "/purchases-view/:id",
    props: true,
    component: () => import("../components/ViewPurchase.vue"),
    name: "Visualizar Compra"
  },
];

export default {};
