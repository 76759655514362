import UserService from '../service'
import { router } from '@/router'

export const actions = {
  listUsers({ commit, dispatch }) {
    dispatch("enableLoading")
    UserService.list()
      .then(res => {
        dispatch("disableLoading")
        commit('setUsers', res.data)
      })
      .catch(e => {
        dispatch("disableLoading")
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.mesage,
          timeout: 6000
        })
      })
  },
  viewUser({ commit, dispatch }, user) {
    dispatch("enableLoading")
    UserService.view(user)
      .then(res => {
        commit('setUser', res.data)
        commit('setPurchases', res.data.purchases)
        commit('setTransactions', res.data.transactions)
        // commit('setUnilevel', res.data.rede)
        dispatch("disableLoading")
      })
      .catch(e => {
        dispatch("disableLoading")
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.mesage,
          timeout: 6000
        })
        
      })
  },
  updateUser({ commit, dispatch, getters }) {
    dispatch("enableLoading")
    UserService.update(getters.getUser)
      .then(res => {
        commit('setUser', res.data)
        dispatch("disableLoading")
      })
      .catch(e => {
        dispatch("disableLoading")
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.mesage,
          timeout: 6000
        })
        
      })
  },
  deleteUser({ commit, dispatch }, user) {
    dispatch("enableLoading")
    UserService.delete(user)
      .then(() => {
        dispatch("disableLoading")
        dispatch("listUsers")
      })
      .catch(e => {
        dispatch("disableLoading")
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.mesage,
          timeout: 6000
        })
        
      })
  },
  enableUnilevel({ commit, dispatch, getters }) {
    dispatch("enableLoading")
    UserService.enableUnilevel({ _id: getters.getUser._id })
      .then(() => {
        dispatch("disableLoading")
        dispatch("createGlobalMessage", {
          type: "success",
          message: "A rede foi ativada",
          timeout: 6000
        })
      })
      .catch(e => {
        dispatch("disableLoading")
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.mesage,
          timeout: 6000
        })
        
      })
  },
  addBalance({ commit, dispatch, getters }) {
    dispatch("enableLoading")
    UserService.addBalance({
      user_id: getters.getUser._id,
      user: getters.getUser,
      description: getters.getAddBalance.description,
      value: getters.getAddBalance.value,
      transaction_type: getters.getAddBalance.transaction_type
    })
      .then(() => {
        dispatch("disableLoading")
        dispatch("createGlobalMessage", {
          type: "success",
          message: "Saldo Atualizado!",
          timeout: 6000
        })
        commit("setModalAddBalance", false)
        commit("setAddBalance", {})
      })
      .catch(e => {
        dispatch("disableLoading")
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.mesage,
          timeout: 6000
        })
      })
  },
  disableUnilevel({ commit, dispatch, getters }) {
    dispatch("enableLoading")
    UserService.disableUnilevel({ _id: getters.getUser._id })
      .then(() => {
        dispatch("disableLoading")
        dispatch("createGlobalMessage", {
          type: "success",
          message: "A rede foi desativada",
          timeout: 6000
        })
      })
      .catch(e => {
        dispatch("disableLoading")
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.mesage,
          timeout: 6000
        })
        
      })
  },
  listRequestPayments({ commit, dispatch }, user) {
    dispatch("enableLoading")
    UserService.listRequestPayments(user)
      .then(res => {
        commit('setRequestPayments', res.data)
        dispatch("disableLoading")
      })
      .catch(e => {
        dispatch("disableLoading")
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.mesage,
          timeout: 6000
        })
        
      })
  },
  deleteTransaction({ commit, dispatch, getters }, payload) {
    dispatch("enableLoading")
    UserService.deleteTransaction(payload)
      .then(() => {
        dispatch('viewUser', getters.getUser)
        dispatch("createGlobalMessage", {
          type: "success",
          message: 'Transação removida',
          timeout: 6000
        })
      })
      .catch(e => {
        dispatch("disableLoading")
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.mesage,
          timeout: 6000
        })
        
      })
  },
  approveRequestPayment({ commit, dispatch }, payload) {
    console.log("asdasd")
    dispatch("enableLoading")
    UserService.approveRequestPayment(payload)
      .then(() => {
        dispatch('listRequestPayments')
        dispatch("disableLoading")
      })
      .catch(e => {
        dispatch("disableLoading")
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.mesage,
          timeout: 6000
        })
        
      })
  },
  cancelRequestPayment({ commit, dispatch }, payload) {
    dispatch("enableLoading")
    UserService.cancelRequestPayment(payload)
      .then(() => {
        dispatch('listRequestPayments')
        dispatch("disableLoading")
      })
      .catch(e => {
        dispatch("disableLoading")
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.mesage,
          timeout: 6000
        })
        
      })
  },
  updatePass({ commit, dispatch }, payload) {
    dispatch("enableLoading")
    UserService.updatePass(payload)
      .then(() => {
        dispatch("disableLoading")
        dispatch("createGlobalMessage", {
          type: "success",
          message: 'Senha alterada com sucesso!',
          timeout: 6000
        })
        dispatch('fechaModalUpdatePass')
      })
      .catch(e => {
        dispatch("disableLoading")
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.mesage,
          timeout: 6000
        })
        
      })
  },
  blockUser({ commit, dispatch, getters }, payload) {
    dispatch("enableLoading")
    UserService.blockUser(getters.getUser)
      .then(() => {
        dispatch("disableLoading")
        dispatch("createGlobalMessage", {
          type: "success",
          message: 'Bloqueado!',
          timeout: 6000
        })
      })
      .catch(e => {
        dispatch("disableLoading")
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.mesage,
          timeout: 6000
        })
        
      })
  },
  unblockUser({ commit, dispatch, getters }, payload) {
    dispatch("enableLoading")
    UserService.unblockUser(getters.getUser)
      .then(() => {
        dispatch("disableLoading")
        dispatch("createGlobalMessage", {
          type: "success",
          message: 'Desbloqueado!',
          timeout: 6000
        })
      })
      .catch(e => {
        dispatch("disableLoading")
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.mesage,
          timeout: 6000
        })
        
      })
  },
  abreModalAddBalance({ commit }) {
    commit('setModalAddBalance', true)
  },
  fechaModalAddBalance({ commit }) {
    commit('setModalAddBalance', false)
  },
  abreModalUpdatePass({ commit }) {
    commit('setModalUpdatePass', true)
  },
  fechaModalUpdatePass({ commit }) {
    commit('setModalUpdatePass', false)
  },
};

export default {};
