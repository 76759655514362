import { http } from "@/apps/shared/baseService/config";

class Service {
  auth(payload) {
    return http.post(`/signin`, payload);
  }

  signup(payload) {
    return http.post(`/signup/${payload.sponsor._id}`, payload);
  }

  verifySponsor(sponsor_id) {
    return http.post(`/sponsor/${sponsor_id}`);
  }
}

export default new Service();
