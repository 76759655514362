import { http } from "@/apps/shared/baseService/config";

class Service {
  list() {
    return http.post("/user/career/list");
  }
  view(data) {
    return http.post("admin/career/view", data)
  }
  update(data) {
    return http.post("admin/career/update", data)
  }
  create(data) {
    return http.post("admin/career/create-career", data)
  }
}

export default new Service();
