import Service from "../service";
import { router } from "@/router";

export const actions = {
  listPaymentMethods: ({ commit, dispatch }, page) => {
    dispatch("enableLoading");

    Service.list()
      .then(res => {
        dispatch("disableLoading");
        commit("setPaymentMethods", res.data);
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 6000
        });
      });
  },
  createPaymentMethod: ({ commit, dispatch, getters }) => {
    dispatch("enableLoading");

    Service.create(getters.getPaymentMethod)
      .then(() => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "success",
          message: 'Registro Concluído!',
          timeout: 6000
        });
        dispatch('closeModalViewPaymentMethod')
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 6000
        });
      });
  },
  updatePaymentMethod: ({ commit, dispatch, getters }) => {
    dispatch("enableLoading");

    Service.update(getters.getPaymentMethod)
      .then(() => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "success",
          message: 'Registro Concluído!',
          timeout: 6000
        });
        dispatch('closeModalViewPaymentMethod')
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 6000
        });
      });
  },
  deletePaymentMethod: ({ commit, dispatch, getters }, paymentMethod) => {
    dispatch("enableLoading");

    Service.delete(paymentMethod)
      .then(() => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "success",
          message: 'Registro Apagado!',
          timeout: 6000
        });
        dispatch('listPaymentMethods')
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 6000
        });
      });
  },
  openModalViewPaymentMethod(state, payload) {
    if(payload) {
      state.commit('setPaymentMethod', payload)
    } else {
      state.commit('setPaymentMethod', {})
    }
    state.commit('setModalViewPaymentMethod', true)
  },
  closeModalViewPaymentMethod(state) {
    state.commit('setPaymentMethod', {})
    state.commit('setModalViewPaymentMethod', false)
  }
};

export default {};
