export const state = {
  users: [],
  user: {},
  markedUsers: [],
  requestPayments: [],
  addBalance: {},
  modalAddBalance: false,
  modalUpdatePass: false,
};

export default {};
