import PurchaseService from "../service";

export const actions = {
  listPurchases({ commit, dispatch }) {
    dispatch("enableLoading")
    PurchaseService.list()
    .then(res => {
      dispatch("disableLoading");
      commit("setPurchases", res.data);
    })
    .catch(e => {
      dispatch("disableLoading");
      dispatch("createGlobalMessage", {
        type: "warning",
        message: e.responsa.data.message,
        timeout: 6000
      });
    });
  },
  viewPurchases({ commit, dispatch }, payload) {
    dispatch("enableLoading")
    commit("setPurchase", {});
    commit("setLinks", {})
    PurchaseService.view(payload)
      .then(res => {
        dispatch("disableLoading");
        commit("setPurchase", res.data);
        commit("setLinks", res.data.links)
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "warning",
          message: e.responsa.data.message,
          timeout: 6000
        });
      });
  },
  aprrovePurchase({ dispatch }, payload) {
    dispatch("enableLoading")
    PurchaseService.approve(payload)
      .then(() => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "success",
          message: 'Compra Aprovada com Sucesso!',
          timeout: 6000
        });
        location.reload()
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "warning",
          message: e.responsa.data.message,
          timeout: 6000
        });
      });
  },
  deletePurchase({ dispatch }, payload) {
    dispatch("enableLoading")
    PurchaseService.delete(payload)
      .then(() => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "success",
          message: 'Compra Removida Com Sucesso!',
          timeout: 6000
        });
        dispatch("listPurchases");
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "warning",
          message: e.responsa.data.message,
          timeout: 6000
        });
      });
  },
  cancelPurchase({ commit }) {
    commit("setModalViewPurchase", false);
    commit("setPurchase", {});
  },
  createPurchase({ commit, dispatch, getters }) {
    dispatch("enableLoading");
    PurchaseService.createPurchase({
      item: getters.getPurchase,
      ...getters.getPurchase,
      paymentMethod: getters.getPaymentMethod,
      images: getters.getLinks
    })
      .then(() => {
        commit("setModalViewPurchase", false);
        dispatch("disableLoading");
        dispatch("cancelPurchase")
        dispatch("listPurchases");
        dispatch("createGlobalMessage", {
          type: "success",
          message: "Sua compra foi enviada para processamento com sucesso!",
          timeout: 6000
        })
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("cancelPurchase")
        dispatch("createGlobalMessage", {
          type: "warning",
          message: e.response.data.message,
          timeout: 6000
        });
      });
  },
  viewPurchase({ commit }, payload) {
    commit("enableLoading");

    PurchaseService.viewPurchase(payload._id)
      .then(res => {
        commit("disableLoading");
        commit("setTransaction", res.data);
        commit("setModalViewPurchase", false);
      })
      .catch(e => {
        commit("disableLoading");
        commit("createGlobalMessage", {
          type: "warning",
          message: e.responsa.data.message,
          timeout: 6000
        });
      });
  },
  updateStatus({ commit, dispatch, getters }, payload) {
    dispatch("enableLoading");

    PurchaseService.updateStatus(payload)
      .then(() => {
        dispatch("disableLoading");
        location.reload()
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "warning",
          message: e.responsa.data.message,
          timeout: 6000
        });
      });
  },
  approve_frete({ commit, dispatch, getters }, payload) {
    dispatch("enableLoading");

    PurchaseService.approve_frete(payload)
      .then(() => {
        dispatch("disableLoading");
        location.reload()
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "warning",
          message: e.responsa.data.message,
          timeout: 6000
        });
      });
  }
};

export default {};
