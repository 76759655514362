export const state = {
  balanceUnilevel: {
    extracts: [],
    balance: 0,
  },
  balanceInvestment: {
    extracts: [],
    balance: 0,
  },
  balanceToken: {
    extracts: [],
    balance: 0,
  }
};

export default {};
