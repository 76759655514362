export const mutations = {
  setModalViewTransaction(state, payload) {
    state.modalViewTransaction = payload;
  },
  setTransactions(state, payload) {
    state.transactions = payload;
  },
  setTransaction(state, payload) {
    state.transaction = payload;
  },
  set_reports(state, payload) {
    state.reports = payload;
  }
};

export default {};
