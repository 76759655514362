export const mutations = {
  setUsers(state, payload) {
    state.users = payload
  },
  setUser(state, payload) {
    state.user = payload
  },
  setMarkedUsers(state, payload) {
    state.markedUsers = payload
  },
  setRequestPayments(state, payload) {
    state.requestPayments = payload
  },
  setAddBalance(state, payload) {
    state.addBalance = payload
  },
  setModalAddBalance(state, payload) {
    state.modalAddBalance = payload
  },
  setModalUpdatePass(state, payload) {
    state.modalUpdatePass = payload
  }
};

export default {};
