import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import { system_actions_routes } from "./apps/0#SystemActions/router";
import { dashboard_routes } from "./apps/dashboard/router";
import { logged_user_routes } from "./apps/2#userLogged/router";
import { users_routes } from "./apps/2#users/router";
import { auth_routes } from "./apps/2#auth/router";
import { unilevel_routes } from "./apps/3#unilevel/router";
import { product_routes } from "./apps/4#product/router";
import { career_routes } from "./apps/5#careers/router";
import { payment_method_routes } from "./apps/7#paymentMethods/router";
import { transaction_routes } from "./apps/8#transactions/router";
import { addresses_routes } from "./apps/9#addresses/router";
import { permission_routes } from "./apps/10#permissions/router";
import { purchase_routes } from "./apps/11#purchases/router";
import { ticket_routes } from "./apps/12#tickets/router";
import { discount_routes } from "./apps/13#discountCoupons/router";
import { investment_routes } from "./apps/14#investments/router";
import Notification from "./apps/6#notifications/meta";

const routes = [
  ...auth_routes,
  ...dashboard_routes,
  ...product_routes,
  ...investment_routes,
  ...career_routes,
  ...purchase_routes,
  ...payment_method_routes,
  ...ticket_routes,
  ...transaction_routes,
  ...addresses_routes,
  ...permission_routes,
  ...discount_routes,
  ...unilevel_routes,
  ...logged_user_routes,
  ...users_routes,
  ...system_actions_routes,
  ...Notification.routes
];

export const router = new VueRouter({
  mode: process.env.CORDOVA_PLATFORM ? "history" : "hash",
  base: process.env.BASE_URL,
  routes
});

export default router;
