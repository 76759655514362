import { http } from "@/apps/shared/baseService/config";

class Service {
  list() {
    return http.post('/admin/user/list')
  }
  view(data) {
    return http.post('/admin/user/view', data)
  }
  listRequestPayments(data) {
    return http.post('/admin/user/list-request-payments', data)
  }
  approveRequestPayment(data) {
    return http.post('/admin/user/approve-withdraw', data)
  }
  cancelRequestPayment(data) {
    return http.post('/admin/user/cancel-withdraw', data)
  }
  enableUnilevel(data) {
    return http.post('/admin/user/enable-unilevel', data)
  }
  disableUnilevel(data) {
    return http.post('/admin/user/disable-unilevel', data)
  }
  addBalance(data) {
    return http.post('/admin/user/add-balance', data)
  }
  update(data) {
    return http.post('/admin/user/update', data)
  }
  delete(data) {
    return http.post('/admin/user/delete', data)
  }
  updatePass(data) {
    return http.post('/admin/user/update-password', data)
  }
  blockUser(data) {
    return http.post('/admin/user/block-user', data)
  }
  unblockUser(data) {
    return http.post('/admin/user/unblock-user', data)
  }
  deleteTransaction(data) {
    return http.post('/admin/transaction/delete', data)
  }
}

export default new Service();
