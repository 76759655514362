<template>
  <v-container
    style="flex-direction: column; background: rgba(0,0,0,0) "
    fluid
    class="molde pa-0 ma-0"
  >
    <v-layout column>
      <v-app-bar
        clipped-left
        app
        dense
        :fixed="$vuetify.breakpoint.smAndDown"
        class="elevation-1"
        color="grey darken-4"
        :style="
          $vuetify.breakpoint.smAndDown
            ? 'margin-bottom: 0px; border-radius: 0px'
            : 'border-radius: 0px;margin-bottom: 0px'
        "
        style="justify-content: center; align-items: center;"
      >
        <v-btn @click="drawer = !drawer" icon>
          <v-icon :color="$theme.primary">mdi-menu</v-icon>
        </v-btn>

        <v-btn v-if="this.icon !== undefined" @click="goBack()" icon>
          <v-avatar class="elevation-0">
            <v-icon :color="$theme.primary"> arrow_back </v-icon>
          </v-avatar>
        </v-btn>

        <!-- <img width="20" height="20" :src="$theme.logo" /> -->

        <span class="fonte ml-2" :style="`color: ${$theme.primary}`">{{
          title
        }}</span>

        <v-spacer></v-spacer>

        <!-- <v-flex v-if="$vuetify.breakpoint.lgAndUp" class="pt-4 px-2" md3>
              <v-text-field solo-inverted dark flat dense v-model="pesquisa" placeholder="Faça uma busca" :color="$theme.primary" append-icon="mdi-magnify"></v-text-field>
          </v-flex> -->

        <!-- <v-btn small v-for="item in options" :color="$theme.primary" class="elevation-0 hidden-sm-and-down animated flipInX delay-1s mr-1" @click="item.action()" :key="item.nome">
              <v-icon class="mr-1" color="#fff"> {{ item.icon }} </v-icon>
              <span class="white--text text-capitalize fonteCash" >{{ item.nome}}</span> 
          </v-btn> -->

        <v-menu bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon :color="$theme.primary">mdi-bell-outline</v-icon>
            </v-btn>
          </template>

          <v-list width="300">
            <v-list-item
              v-for="notification in get_notifications.docs"
              :key="notification._id"
              @click="() => item.action()"
            >
              <v-list-item-avatar>
                <v-icon>mdi-bell-outline</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="fonte">{{ notification.title }}</v-list-item-title>
                <v-list-item-subtitle class="fonte">{{ notification.message }}</v-list-item-subtitle>
                <v-list-item-subtitle class="orange--text fonte" style="font-size: 9pt;">{{ $moment(notification.created_at).format('llll') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu left bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon :color="$theme.primary"
                >mdi-account-circle-outline</v-icon
              >
            </v-btn>
          </template>

          <v-list nav dense>
            <v-list-item to="/logged-user">
              <v-list-item-title>{{ getLoggedUser.name }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-title>sair</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu left bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon :color="$theme.primary">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="item in options"
              :key="item.nome"
              @click="() => item.action()"
            >
              <v-list-item-title>{{ item.nome }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <v-navigation-drawer
        dark
        app
        color="#222"
        width="250"
        clipped
        class="elevation-6"
        v-model="drawer"
      >
        <v-list nav dense>
          <v-list-item
            active-class="menuAtivoHome"
            to="/dashboard"
            class="ml-0 pl-0"
          >
            <v-icon
              size="16"
              class="ml-2 mr-6"
              :color="
                $route.path === '/dashboard' ? 'white--text' : $theme.primary
              "
            >
              mdi-grid
            </v-icon>
            <v-list-item-content>
              <v-list-item-title>
                <span
                  class="fonteCash fonteMini"
                  :class="
                    $route.path === '/dashboard' ? 'white--text' : 'white--text'
                  "
                  >Dashboard</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            active-class="menuAtivoHome"
            to="/pay-investment"
            class="ml-0 pl-0"
          >
            <v-icon
              size="16"
              class="ml-2 mr-6"
              :color="
                $route.path === '/pay-investment' ? 'white--text' : $theme.primary
              "
            >
              mdi-grid
            </v-icon>
            <v-list-item-content>
              <v-list-item-title>
                <span
                  class="fonteCash fonteMini"
                  :class="
                    $route.path === '/pay-investment' ? 'white--text' : 'white--text'
                  "
                  >Informar Ganho de Ativo</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
          <v-list-item
            active-class="menuAtivoHome"
            to="/paymentmethods"
            class="ml-0 pl-0"
          >
            <v-icon
              size="16"
              class="ml-2 mr-6"
              :color="
                $route.path === '/paymentmethods' ? 'white--text' : $theme.primary
              "
            >
              mdi-cash
            </v-icon>
            <v-list-item-content>
              <v-list-item-title>
                <span
                  class="fonteCash fonteMini"
                  :class="
                    $route.path === '/paymentmethods' ? 'white--text' : 'white--text'
                  "
                  >Formas de Pagamento</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
          <v-list-item
            active-class="menuAtivoHome"
            to="/purchases"
            class="ml-0 pl-0"
          >
            <v-icon
              size="16"
              class="ml-2 mr-6"
              :color="
                $route.path === '/purchases' ? 'white--text' : $theme.primary
              "
            >
              mdi-basket-outline
            </v-icon>
            <v-list-item-content>
              <v-list-item-title>
                <span
                  class="fonteCash fonteMini"
                  :class="
                    $route.path === '/purchases' ? 'white--text' : 'white--text'
                  "
                  >Compras</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
          <v-list-item
            active-class="menuAtivoHome"
            to="/users"
            class="ml-0 pl-0"
          >
            <v-icon
              size="16"
              class="ml-2 mr-6"
              :color="
                $route.path === '/users' ? 'white--text' : $theme.primary
              "
            >
              mdi-account-circle
            </v-icon>
            <v-list-item-content>
              <v-list-item-title>
                <span
                  class="fonteCash fonteMini"
                  :class="
                    $route.path === '/users' ? 'white--text' : 'white--text'
                  "
                  >Usuários</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
          <v-list-item
            active-class="menuAtivoHome"
            to="/request-payments"
            class="ml-0 pl-0"
          >
            <v-icon
              size="16"
              class="ml-2 mr-6"
              :color="
                $route.path === '/request-payments' ? 'white--text' : $theme.primary
              "
            >
              mdi-cash
            </v-icon>
            <v-list-item-content>
              <v-list-item-title>
                <span
                  class="fonteCash fonteMini"
                  :class="
                    $route.path === '/request-payments' ? 'white--text' : 'white--text'
                  "
                  >Pedidos de Saque</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            active-class="menuAtivoHome"
            to="/notifications"
            class="ml-0 pl-0"
          >
            <v-icon
              size="16"
              class="ml-2 mr-6"
              :color="
                $route.path === '/notifications'
                  ? 'white--text'
                  : $theme.primary
              "
            >
              mdi-bell-outline
            </v-icon>
            <v-list-item-content>
              <v-list-item-title>
                <span
                  class="fonteCash fonteMini"
                  :class="
                    $route.path === '/notifications'
                      ? 'white--text'
                      : 'white--text'
                  "
                  >Notificações</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group no-action :color="$theme.primary">
            <template v-slot:activator>
              <v-list-item class="ml-0 pl-0">
                <v-icon size="16" class="mr-6" :color="$theme.primary">
                  mdi-store-outline
                </v-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="fonteCash fonteMini">Loja</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <!-- <v-list-item
              v-if="show_ativos"
              class="pl-6"
              active-class="menuAtivoHome"
              to="/investments"
            >
              <v-avatar>
                <v-icon
                  :color="
                    $route.path === '/investments' ? 'white' : $theme.primary
                  "
                >
                  mdi-chevron-right
                </v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title
                  :class="
                    $route.path === '/investments'
                      ? 'white--text'
                      : 'white--text'
                  "
                  class="fonteCash"
                >
                  Planos de investimento
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->

            <!-- <v-list-item
              v-if="show_produtos"
              class="pl-6"
              active-class="menuAtivoHome"
              to="/products"
            >
              <v-avatar>
                <v-icon
                  :color="
                    $route.path === '/products' ? 'white' : $theme.primary
                  "
                >
                  mdi-chevron-right
                </v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title
                  :class="
                    $route.path === '/products' ? 'white--text' : 'white--text'
                  "
                  class="fonteCash"
                >
                  Produtos
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->

            <v-list-item
              v-if="show_carreiras"
              class="pl-6"
              active-class="menuAtivoHome"
              to="/careers"
            >
              <v-avatar>
                <v-icon
                  :color="$route.path === '/careers' ? 'white' : $theme.primary"
                >
                  mdi-chevron-right
                </v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title
                  :class="
                    $route.path === '/careers' ? 'white--text' : 'white--text'
                  "
                  class="fonteCash"
                >
                  Licenças
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <!-- finanças -->
          <!-- <v-list-group no-action :color="$theme.primary">
            <template v-slot:activator>
              <v-list-item class="ml-0 pl-0">
                <v-icon size="16" class="mr-6" :color="$theme.primary">
                  mdi-cash
                </v-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="fonteCash fonteMini">Finanças</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list-item
              class="pl-6"
              active-class="menuAtivoHome"
              to="/purchases"
            >
              <v-avatar>
                <v-icon
                  :color="
                    $route.path === '/purchases' ? 'white' : $theme.primary
                  "
                >
                  mdi-chevron-right
                </v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title
                  :class="
                    $route.path === '/purchases' ? 'white--text' : 'white--text'
                  "
                  class="fonteCash fonteMini"
                >
                  Minhas Compras
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->

            <!-- <v-list-item class="pl-6" active-class="menuAtivoHome" to="/my-investments">
              <v-avatar>
                <v-icon
                  :color="$route.path === '/my-investments' ? 'white' : $theme.primary"
                >
                  mdi-chevron-right
                </v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title
                  :class="
                    $route.path === '/my-investments' ? 'white--text' : 'white--text'
                  "
                  class="fonteCash fonteMini"
                >
                  Meus Ativos
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
            
            <!-- <v-list-item class="pl-6" active-class="menuAtivoHome" to="/transactions">
              <v-avatar>
                <v-icon
                  :color="$route.path === '/transactions' ? 'white' : $theme.primary"
                >
                  mdi-chevron-right
                </v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title
                  :class="
                    $route.path === '/transactions' ? 'white--text' : 'white--text'
                  "
                  class="fonteCash fonteMini"
                >
                  Meus Extratos
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
<!-- 
            <v-list-item class="pl-6" active-class="menuAtivoHome" to="/tokens">
              <v-avatar>
                <v-icon
                  :color="$route.path === '/tokens' ? 'white' : $theme.primary"
                >
                  mdi-chevron-right
                </v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title
                  :class="
                    $route.path === '/tokens' ? 'white--text' : 'white--text'
                  "
                  class="fonteCash fonteMini"
                >
                  Meus Tokens
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              class="pl-6"
              active-class="menuAtivoHome"
              to="/extratos"
            >
              <v-avatar>
                <v-icon
                  :color="
                    $route.path === '/extratos' ? 'white' : $theme.primary
                  "
                >
                  mdi-chevron-right
                </v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title
                  :class="
                    $route.path === '/extratos' ? 'white--text' : 'white--text'
                  "
                  class="fonteCash fonteMini"
                >
                  Meu Saldo
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
          <!-- </v-list-group> -->

          <!-- carreira -->
          <!-- <v-list-group no-action :color="$theme.primary">
            <template v-slot:activator>
              <v-list-item class="ml-0 pl-0">
                <v-avatar class="mr-6" size="16">
                  <v-img :src="$theme.token_img">
                  </v-img>
                </v-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="fonteCash fonteMini">MB Tokens</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list-item
              class="pl-6"
              active-class="menuAtivoHome"
              to="/premiacoes"
            >
              <v-avatar>
                <v-icon
                  :color="
                    $route.path === '/premiacoes' ? 'white' : $theme.primary
                  "
                >
                  mdi-chevron-right
                </v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title
                  :class="
                    $route.path === '/premiacoes'
                      ? 'white--text'
                      : 'white--text'
                  "
                  class="fonteCash fonteMini"
                >
                  Graduações
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              class="pl-6"
              active-class="menuAtivoHome"
              to="/carreira"
            >
              <v-avatar>
                <v-icon
                  :color="
                    $route.path === '/carreira' ? 'white' : $theme.primary
                  "
                >
                  mdi-chevron-right
                </v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title
                  :class="
                    $route.path === '/carreira' ? 'white--text' : 'white--text'
                  "
                  class="fonteCash fonteMini"
                >
                  Ranking
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group> -->

          <!-- <v-list-item
            active-class="menuAtivoHome"
            to="/tickets"
            class="ml-0 pl-0"
          >
            <v-icon size="16" class="ml-2 mr-6" :color="$route.path === '/tickets' ? 'white' : $theme.primary">
              mdi-face-agent
            </v-icon>
            <v-list-item-content>
              <v-list-item-title>
                <span class="fonteCash fonteMini white--text">Suporte</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

          <!-- <v-list-item
            active-class="menuAtivoHome"
            to="/logged-user"
            class="ml-0 pl-0"
          >
            <v-icon size="16" class="ml-2 mr-6" :color="$route.path === '/logged-user' ? 'white' : $theme.primary">
              mdi-account-circle
            </v-icon>
            <v-list-item-content>
              <v-list-item-title>
                <span class="fonteCash white--text fonteMini">Meu Perfil</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <v-list-item
            active-class="menuAtivoHome"
            to="/systemactions"
            class="ml-0 pl-0"
          >
            <v-icon
              size="16"
              class="ml-2 mr-6"
              :color="
                $route.path === '/systemactions' ? 'white--text' : $theme.primary
              "
            >
              mdi-cog-outline
            </v-icon>
            <v-list-item-content>
              <v-list-item-title>
                <span
                  class="fonteCash fonteMini"
                  :class="
                    $route.path === '/systemactions' ? 'white--text' : 'white--text'
                  "
                  >Configurações</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-main class="molde">
        <div
          v-if="$vuetify.breakpoint.smAndUp"
          class="expande-horizontal centraliza px-3 py-3"
        >
          <v-btn dark @click="$router.go(-1)" icon>
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <span style="font-size: 18pt;" class="fonte white--text">
            {{ title }}
          </span>
          <v-spacer></v-spacer>
          <v-btn v-for="item in options" :color="$theme.primary" class="elevation-0 hidden-sm-and-down animated flipInX delay-1s mr-1" @click="item.action()" :key="item.nome">
              <v-icon class="mr-1" color="#fff"> {{ item.icon }} </v-icon>
              <span class="white--text text-capitalize fonteCash" >{{ item.nome}}</span> 
          </v-btn>
        </div>
        <v-divider dark></v-divider>
        <slot></slot>
      </v-main>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["title", "options", "icon", "action", "origem"],
  data() {
    return {
      drawer: true,
      show_ativos: process.env.VUE_APP_LOJA_ATIVOS,
      show_produtos: process.env.VUE_APP_LOJA_PRODUTOS,
      show_carreiras: process.env.VUE_APP_LOJA_CARREIRAS,
    };
  },
  computed: {
    ...mapGetters([
      'getLoggedUser',
      'get_notifications'
    ])
  },
  methods: {
    ...mapActions([
      'listNotifications',
      'logout'
    ]),
    goBack() {
      if (this.action !== undefined) {
        this.action();
      } else {
        this.$router.go(-1);
      }
    }
  },
  created() {
    this.drawer = this.$vuetify.breakpoint.smAndUp;
    this.listNotifications()
  }
};
</script>

<style>
.centraliza-tudo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.molde {
  display: flex;
  width: 100%;
  background: #333;
}
</style>
